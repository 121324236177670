import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../../global.css'
import favicon from '../../favicon.png'
import Nav from '../../navigation.js'
import Footer from '../../footer.js'

import foundationBg from '../../foundationBg.jpg'
import foundation from '../../foundation.jpg'

var splendoBlue = '#2F76CA'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .sectionHeader {
    font-size: 1.4em;
    padding: 30px 0;
  }
`

const Hero = styled.header`
  text-align: left;
  position: relative;
  height: 50%;
  background-image: url(${foundationBg});
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    text-align: center;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 600px;
    padding-left: 10%;
    @media (max-width: 800px) {
      padding: 0;
      transform: translateY(-50%);
    }
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
    }
  }
`
const Details = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 80px 0;
  @media (max-width: 800px) {
    text-align: center;
    width: 90%;
    padding: 50px 0;
  }
  h1 {
    padding-bottom: 30px;
  }

  p {
    font-family: nunitoReg, sans-serif;
    padding: 0 0 40px;
    width: 90%;
    max-width: 800px;
    @media (max-width: 800px) {
      font-size: 1em;
      text-align: center;
      width: 100%;
    }
  }

  a {
    display: inline-block;
    padding: 20px 40px;
    color: white;
    border-radius: 12px;
    background: ${splendoBlue};
    text-transform: uppercase;
  }
  .details {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
    div {
      flex: 50%;
      @media (max-width: 800px) {
        flex: 0 100%;
      }
    }
    img {
      border-radius: 18px;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`
export default () => (
  <Container>
    <Wrapper>
      <Helmet>
        <title>Splendo</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Splendo" />
        <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>

      <Nav />

      <Hero>
        <div className="wrapper">
          <h1>
            The Splendo <br /> Health Foundation
          </h1>
        </div>
      </Hero>

      <Details>
        <h1 className="sectionHeader">About The Foundation</h1>

        <div className="details">
          <div>
            <p>
              The Splendo Health Foundation was competitor in The Qualcomm Tricorder XPRIZE and has motivated Luc and his team to create new
              technologies that really can improve people's lives. This competition stimulates the exchange of innovative ideas between creative
              people with various backgrounds and areas of expertise.
            </p>
            <p>
              The multidisciplinary prerequisites is inspiring to gain new insights and bring objectives within reach that were formerly considered
              unfeasible. The Splendo Health Foundation was pleased to participate in the Qualcomm Tricorder XPRIZE and hopes to contribute to the
              creation of a better and healthier world, as every person on this earth is entitled to good and affordable health care.
            </p>
          </div>

          <div>
            <img src={foundation} alt="Foundation" />
          </div>
        </div>
      </Details>

      <Footer />
    </Wrapper>
  </Container>
)
